<template>
  <action-edit
    :action-url="`product-components/${item.id}`"
    :item="item"
    button-label="Editeaza componenta"
    dialog-width="500px"
  >

    <template v-slot:title>
      Editeaza componenta
    </template>

    <template v-slot:fields="{form, errors}">
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="form.name" dense hide-details label="Denumire scurta reteta" outlined/>
          <error-messages :errors="errors.name"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="form.internal_name" dense hide-details label="Denumire Gestiune/Site" outlined/>
          <error-messages :errors="errors.internal_name"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <product-component-group-selector v-model="form.group_id" add-new-button label="Grupa componentei"/>
          <error-messages :errors="errors.group_id"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <product-component-selector v-model="form.component_type_id" label="Tipul componentei"/>
          <error-messages :errors="errors.component_type_id"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <badge-selector v-model="form.badges"/>
          <error-messages :errors="errors.badges"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="form.price" dense hide-details label="Pret" outlined/>
          <error-messages :errors="errors.price"/>
        </v-col>
        <v-col>
          <v-text-field v-model="form.buying_price" dense hide-details label="Pret achizitie" outlined/>
          <error-messages :errors="errors.buying_price"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="form.stock" dense hide-details label="Stoc" outlined/>
          <error-messages :errors="errors.stock"></error-messages>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="form.display_order" dense hide-details label="Ordine afisare" outlined type="number"/>
          <error-messages :errors="errors.display_order"></error-messages>
        </v-col>
      </v-row>
    </template>

  </action-edit>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductComponentGroupSelector from '@/components/autocompletes/ProductComponentGroupSelector'
import ProductComponentSelector from '@/components/autocompletes/ProductComponentSelector'
import BadgeSelector from '@/components/autocompletes/BadgeSelector'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  name: 'EditComponent',
  components: {
    ActionEdit,
    BadgeSelector,
    ProductComponentSelector,
    ProductComponentGroupSelector,
    ErrorMessages,
    ActionAddNew
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
