<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title class="headline">
        <div>
          Actualizare stoc:
        </div>
        <div class="font-weight-bold">
          {{ component.name }}
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="4">
            Stoc curent: <br/> <strong>{{ component.stock }} Buc</strong>
          </v-col>
          <v-col cols="4">
            Pret achizitie curent: <br/> <strong>{{ component.buying_price }} Lei</strong>
          </v-col>
          <v-col cols="4">
            Pret vânzare curent: <br/> <strong>{{ component.price }} Lei</strong>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="newStock" label="Adaugă cantitate" type="number"/>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="newBuyingPrice" label="având Pret achizitie" type="number"/>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="newPrice" label="Pret vânzare" type="number" :disabled="updateOption ==='keep'"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select v-model="updateOption" :items="updateOptions"></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="gray darken-1" text @click="close">
          Anulare
        </v-btn>

        <v-btn color="green darken-1" text @click="update">
          Actualizează
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StockUpdater',
  props: {
    dialog: {
      required: true,
      default: false
    },
    component: {
      required: true
    }
  },
  data () {
    return {
      newStock: 0,
      newPrice: 0,
      newBuyingPrice: 0,
      updateOption: 'unify',
      updateOptions: [
        {
          value: 'keep',
          text: 'Pastreaza pretul actual de vânzare'
        },
        {
          value: 'unify',
          text: 'Uniformizeaza pretul de vânzare'
        },
        {
          value: 'update',
          text: 'Seteaza manual pretul de vânzare'
        }
      ]
    }
  },
  watch: {
    updateOption (newVal) {
      if (newVal === 'unify') {
        this.newPrice = 0
      } else {
        this.newPrice = this.component.price
      }
    },

    dialog (newVal) {
      if (newVal) {
        this.newStock = 0
        this.updateOption = 'unify'
        this.newPrice = 0
        this.newBuyingPrice = 0
      }
    }
  },
  methods: {
    update () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`product-components/${this.component.id}/update-quantity`, {
        newStock: this.newStock,
        newBuyingPrice: this.newBuyingPrice,
        newPrice: this.newPrice,
        updateOption: this.updateOption
      })
        .then(() => {
          this.$store.dispatch('components/loadItems')
            .then(() => {
              this.$store.dispatch('stopLoading')
              this.$emit('close')
            })
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
